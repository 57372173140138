:root {
	--sidebar-width-active: 30vw;
	--sidebar-width: 72px;
}

@media (max-width: 1200px) {
	:root {
		--sidebar-width-active: 600px;
	}
}

@media (max-width: 600px) {
	:root {
		--sidebar-width-active: 100vw;
	}
}

@media (max-width: 430px) {
	:root {
		--sidebar-width: 40px;
	}
}

// Colors
$color__green: #71b340 !default;
$color__red: #ff6b6b !default;
$color__cyan: #00adb5 !default;
$color__dark: #0b0d22 !default;
$color__white: #ffffff !default;
$color__orange: #ff8a00 !default;
$color__blue: #2b338a !default;
$color__dark_blue: #222831 !default;
$color__light_blue: #3e4ace !default;
$color__semi_white: rgba($color__white, 0.1) !default;
$color__semi_white2: rgba($color__white, 0.7) !default;
$color__semi_dark: rgba($color__dark, 0.1) !default;
$color__semi_red: rgba($color__red, 0.25) !default;
$color__semi_green: rgba($color__green, 0.25) !default;
$color__gray: #f2f2f2 !default;

$color__primary: $color__blue;
$color__text: $color__dark;
$color__background: $color__gray;
$color__link: $color__light_blue;

// Effects
$box_shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
$box_shadow__input: 0px 1px 3px rgba(0, 0, 0, 0.1);
$border_radius: 4px;
$border_color: $color__semi_dark;
$border: 1px solid $border_color;
$color__hover: #f8f8f8;

// Structure
$default_padding: 20px;
$site__padding: $default_padding * 2;
$sidebar__width_active: var(--sidebar-width-active);
$sidebar__width: var(--sidebar-width);
$sidebar__padding: $default_padding * 2;
$admin_padding_right: 160px;

$breakpoints: (
	'tiny': 430px,
	'small': 768px,
	'medium': 1010px,
	'large': 1200px,
) !default;

// Typography
$font__size: 16px;
$font__text: 'Lato', sans-serif;
$font__bold: 700;
$font__semibold: 400;
$font__normal: 300;
$line__height: 1.35em;
