
					@import './src/sass/variables';
					@import './src/sass/mixins';
				











































































































































































































.block.recaudio {
	.recording {
		.audio-background button {
			background-color: $color__red;
			padding: 20px;

			&:hover {
				background-color: darken($color__red, 5%);
			}

			svg {
				display: none;
			}

			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background-color: $color__white;
			}
		}
	}

	.audio-background {
		position: relative;
		height: 160px;
		background-color: $color__dark_blue;
		border-radius: $border_radius;


		canvas {
			width: 100%;
			height: 100%;
		}

		button {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
			border-radius: 50%;
			background-color: $color__light_blue;
			color: $color__white;
			padding: 14px;
			border: 3px solid $color__background;
			transition: background-color .2s ease;
			outline: none;

			&:hover {
				background-color: $color__blue;
			}

			svg {
				display: block;
			}
		}
	}

	.meta {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
	}

	.audio-background,
	.meta {
		max-width: 600px;
	}
}
